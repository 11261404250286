// @import "fonts";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;700&display=swap");

@import "variables";
@import "mixins";

html,
body {
  background: white;
  font-family: $font_Main;
  // overflow-x: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  padding-top: $s_headerHeight;
  padding-top: 58px;
}

html {
  scroll-behavior: smooth;
  background-color: $c_darkBlue;
}

// Classes
.uhub_buttonBlue {
  background-color: $c_lightBlue;
  color: $c_white;
  border: 1px solid $c_lightBlue;
  font-size: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  width: 460px;
  height: 51px;
  border-radius: 25px;
  line-height: 3;
  @include mixin_animation(all);
  &:hover {
    border: 1px solid $c_darkBlue;
    background-color: $c_darkBlue;
  }
}
.uhub_buttonHollow {
  border: 1px solid $c_darkBlue;
  background-color: $c_white;
  color: $c_darkBlue;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  width: 460px;
  height: 51px;
  border-radius: 25px;
  line-height: 3.2;
  @include mixin_animation(all);
  &:hover {
    border: 1px solid $c_lightBlue;
    background-color: $c_lightBlue;
    color: $c_white;
  }
}
.uhub_featureIllustrations {
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-color: $c_verylightGrey;
  text-align: center;
  padding-top: 30px;
}
// Header
#uhub_Header {
  z-index: 1;
  background-color: $c_darkBlue;
  color: $c_lightGrey;
  height: $s_headerHeight;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto;
  padding: 30px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100vw;
  div:first-of-type {
    // border: 1px solid red;
    align-self: center;
    display: flex;
    img {
      width: 100px;
      height: 40px;
      margin-top: 11px;
    }
  }
  div:nth-of-type(2) {
    // border: 1px solid green;
    text-align: center;
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 500px;
    margin: 0 auto;
    a {
      margin: 0 44px;
      font-size: 1.1rem;
      color: $c_lightGrey;
      text-decoration: none;
      align-self: center;
      height: 100%;
      line-height: 3.2;
      @include mixin_animation(color);
      &:hover {
        color: $c_white;
      }
    }
  }
  div:last-of-type {
    // border: 1px solid yellow;
    text-align: right;
    a {
      width: 238px;
      height: 53px;
      color: $c_lightBlue;
      border: 1px solid $c_lightBlue;
      align-self: center;
      text-align: center;
      margin-left: 25px;
      text-decoration: none;
      font-size: 1.1rem;
      border-radius: 28px;
      text-transform: uppercase;
      display: inline-block;
      line-height: 3;
      @include mixin_animation(all);
      &:hover {
        color: $c_white;
        background-color: $c_lightBlue;
      }
    }
  }
  @include mixin_animation(all);
}

body.uhub_Scrolled {
  > #uhub_Header {
    height: calc(#{$s_headerHeight} - 50px);
    padding-top: 6px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.8);
    div:last-of-type a {
      height: 33px;
      margin-top: 10px;
      line-height: 1.9;
    }
  }
}

// Welcome
#uhub_Welcome {
  background-color: $c_white;
  color: $c_black;

  padding: 110px;
  box-sizing: border-box;
  padding-bottom: 0;
  padding-top: 0px;
  h1 {
    text-align: center;
    font-size: 3rem;
  }
  img {
    width: 100%;
  }
  > div {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 1.5rem;
    > div:first-of-type {
      // border: 1px solid red;
      text-align: center;
      align-self: center;
    }
    > div:nth-of-type(2) {
      // border: 1px solid green;
      text-align: right;
    }
    // > div:last-of-type {
    //   // border: 1px solid red;
    //   text-align: right;
    //   align-self: center;
    // }
  }
  a.uhub_Button {
    background-color: $c_darkBlue;
    color: $c_white;
    font-size: 1.1rem;
    text-transform: uppercase;
    text-decoration: none;
    padding: 20px 30px;
    border-radius: 40px;
    white-space: nowrap;
    display: inline-block;
    @include mixin_animation(all);
    &:hover {
      color: $c_white;
      background-color: $c_lightBlue;
    }
  }
}

// Features
#uhub_Features {
  div.uhub_Feature {
    // border: 1px solid red;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    > div {
      padding: 80px;
      ul {
        list-style-position: outside;
        padding: 0;
        li {
          font-weight: 100;
          font-size: 0.8rem;
          list-style: none;
          position: relative;
          span {
            font-weight: bold;
            font-size: 1.3rem;
            letter-spacing: 0.05rem;
            margin: 0;
            &::before {
              content: "";
              width: 20px;
              height: 20px;
              display: block;
              position: absolute;
              left: -30px;
              top: 2px;
            }
          }
          p {
            margin-top: 5px;
            font-size: 0.95rem;
          }
        }
      }
      h1 {
        font-size: 3rem;
        font-weight: normal;
        margin-top: 0;
      }
    }
    // Plan
    &:nth-of-type(1) {
      > div:first-child {
        background-color: $c_lightBlue;
        color: $c_white;
        ul {
          li:nth-of-type(1) > span::before {
            background-image: url(../images/icon_plan_strategy.svg);
          }
          li:nth-of-type(2) > span::before {
            background-image: url(../images/icon_plan_schedule.svg);
          }
          li:nth-of-type(3) > span::before {
            background-image: url(../images/icon_plan_collaborate.svg);
          }
        }
      }
      > div:last-child {
        @extend .uhub_featureIllustrations;
      }
    }
    // Create
    &:nth-of-type(2) {
      > div:first-child {
        @extend .uhub_featureIllustrations;
      }
      > div:last-child {
        background-color: $c_green;
        color: $c_white;
        ul {
          li:nth-of-type(1) > span::before {
            background-image: url(../images/icon_create_build.svg);
          }
          li:nth-of-type(2) > span::before {
            background-image: url(../images/icon_create_customize.svg);
          }
          li:nth-of-type(3) > span::before {
            background-image: url(../images/icon_create_content_library.svg);
          }
        }
      }
    }
    // Publish
    &:nth-of-type(3) {
      > div:first-child {
        background-color: $c_lightPurple;
        color: $c_white;
        ul {
          li:nth-of-type(1) > span::before {
            background-image: url(../images/icon_publish_streamline.svg);
          }
          li:nth-of-type(2) > span::before {
            background-image: url(../images/icon_publish_target.svg);
          }
          li:nth-of-type(3) > span::before {
            background-image: url(../images/icon_publish_advertise.svg);
          }
        }
      }
      > div:last-child {
        @extend .uhub_featureIllustrations;
      }
    }
    // Measure
    &:nth-of-type(4) {
      > div:first-child {
        @extend .uhub_featureIllustrations;
      }
      > div:last-child {
        background-color: $c_cyan;
        color: $c_white;
        ul {
          li:nth-of-type(1) > span::before {
            background-image: url(../images/icon_measure_1.svg);
          }
          li:nth-of-type(2) > span::before {
            background-image: url(../images/icon_measure_2.svg);
          }
          li:nth-of-type(3) > span::before {
            background-image: url(../images/icon_measure_3.svg);
          }
        }
      }
    }

    // Understand
    &:nth-of-type(5) {
      > div:first-child {
        background-color: $c_darkPurple;
        color: $c_white;
        ul {
          li:nth-of-type(1) > span::before {
            background-image: url(../images/icon_understand_1.svg);
          }
          li:nth-of-type(2) > span::before {
            background-image: url(../images/icon_understand_2.svg);
          }
          li:nth-of-type(3) > span::before {
            background-image: url(../images/icon_understand_3.svg);
          }
        }
      }
      > div:last-child {
        @extend .uhub_featureIllustrations;
      }
    }
  }
}

//  Call To Action
#uhub_CallToAction {
  background-color: $c_darkBlue;
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  a {
    color: $c_white;
    font-size: 2rem;
    text-align: center;
    padding: 50px;
    width: 100%;
    font-weight: bold;
    text-decoration: none;
  }
}

// BottomStrip
#uhub_BottomStrip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  > a:first-child {
    margin-right: 35px;
  }
}

// Blogs
.uhub_BlogPost_Card {
  width: 300px;

  color: $c_black;
  text-decoration: none;
  display: flex;
  text-align: left;
  flex-direction: column;
  border: 1px solid transparent;
  @include mixin_animation(border);
  &:hover {
    border: 1px solid $c_darkBlue;
  }
  > .uhub_blogDetails {
    border: 1px solid red;
    padding: 20px;
    background: $c_white;
    border: 1px solid $c_darkGrey;
    > span {
      font-weight: bold;
      font-size: 1.3rem;
      color: $c_darkBlue;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
    > p {
      font-weight: 100;
      font-size: 0.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
#uhub_Blogs {
  // border: 1px solid red;
  background: $c_midGrey;
  padding: 50px;
  text-align: center;
  h1 {
    text-align: center;
  }
  > div {
    // border: 1px solid red;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 960px;
    padding: 0;
    margin: 0 auto;
    > a {
      @extend .uhub_BlogPost_Card;
    }
  }
  .uhub_blogReadMore {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 30px;
    display: inline-block;
    margin-top: 30px;
    text-decoration: none;
    border: 1px solid $c_black;
    background-color: $c_black;
    border-radius: 20px;
    color: $c_white;
    @include mixin_animation(all);
    &:hover {
      border: 1px solid $c_lightBlue;
      background-color: $c_lightBlue;
    }
  }
}

// Footer
#uhub_Footer {
  text-align: center;
  color: $c_white;
  background: $c_darkBlue;
  padding: 20px;
  font-weight: 100;
  font-size: 0.8rem;
}

// Blogpost
#uhub_BlogPost {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 110px;
  div.uhub_BlogPost_Date {
    text-align: center;
  }
  div.uhub_BlogPost_Title {
    text-align: center;
    padding: 30px;
    font-size: 2.5rem;
    font-weight: bold;
  }
  div.uhub_BlogPost_Announce {
    text-align: center;
    font-size: 1.2rem;
    color: $c_darkBlue;
    font-weight: normal;
    letter-spacing: -0.025rem;
  }
  div.uhub_BlogPost_Image {
    margin: 30px 0;
    img {
      width: 100%;
    }
  }
  div.uhub_BlogPost_Body {
  }
}

// Blog
#uhub_Blog {
  h1 {
    background: $c_midGrey;
    margin: 0;
    padding: 40px;
    text-align: center;
  }
  > div {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 960px;
    padding: 0;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-row-gap: 20px;
    a {
      @extend .uhub_BlogPost_Card;
    }
  }
}

// Platform
#uhub_Platform {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 110px;
}

// Pricing
#uhub_Pricing {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 110px;
}

@import "mediaqueries";
