/* Smartphones (small devices - portrait) ----------- */
@media only screen and (max-width: 320px) and (orientation: portrait) {
  /* Styles iPhone 5*/
  ul#pw_Navigation li {
    height: 70px;
    a > span {
      height: 70px;
    }
  }
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) and (max-width: 480px) and (orientation: landscape) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (min-width: 321px) and (max-width: 480px) and (orientation: portrait) {
  /* Styles */
  #uhub_Header {
    height: 77px;
    padding: 10px 20px;
  }
  #uhub_Welcome {
    padding: 10px;
    h1 {
      font-size: 2rem;
      margin: 10px;
    }
    > div {
      display: flex;
      flex-direction: column;
      > div:first-of-type {
        font-size: 1.2rem;
        font-weight: 100;
      }
      > div:last-of-type {
        a {
          display: inline-block;
          margin: 10px 0px 20px;
        }
      }
    }
  }
  #uhub_Features div.uhub_Feature {
    grid-template-columns: auto;
  }
  #uhub_Blogs > div {
    grid-template-columns: auto;
    > a {
      margin-bottom: 20px;
    }
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* Styles */
  body {
    #pw_MainCard {
      width: 96%;
    }
    ul#pw_Navigation {
      left: calc(2% + 3px);
      li {
        height: 90px;
        max-height: 90px;
      }
    }
    .pw_LeftArea {
      width: 620px;
    }
    .pw_RightArea {
      min-width: 300px;
    }
    .pw_MainArea img.pw_ProfileImage {
      max-width: 150px;
    }
  }
}

/* iPads (portrait) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* Styles */
  #uhub_Header {
    div:nth-of-type(1) {
      margin-right: 30px;
    }
    div:nth-of-type(2) {
      width: 380px;
      > a {
        margin: 0;
      }
    }
    div:last-of-type a {
      width: 150px;
    }
  }

  #uhub_Welcome {
    padding-top: 0;
    > div {
      display: flex;
      flex-direction: column;
      > div:nth-of-type(2) {
        margin-top: 20px;
      }
      > div:last-of-type a {
        margin-top: 30px;
        display: inline-block;
        margin-bottom: 50px;
      }
    }
  }
}

/* iPad 1 */
@media only screen and (width: 768px) and (orientation: portrait) {
  #uhub_Header div:last-of-type {
    margin-top: -10px;
    a {
      height: 37px;
      line-height: 2.3;
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  /* Styles */
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  /* Styles */
}

/* iPhone 4 ----------- */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  /* Styles */
}
