// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

$font_Main: "Montserrat", sans-serif;
// -----------------------
$c_darkBlue: #162d45;
$c_lightBlue: #00afe8;
$c_lightGrey: #ccc8c4;
$c_white: #fff;
$c_black: #000;
$c_verylightGrey: #e7eaf0;
$c_green: #3fad4d;
$c_lightPurple: #99b0d6;
$c_cyan: #00b7bb;
$c_midGrey: #e8e6e4;
$c_darkGrey: #8a8787;
$c_darkPurple: #546eb0;

$s_headerHeight: 117px;
